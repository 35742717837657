const globalState = {
    search: {
        text: ''
    },
    data: {
        selectedAccountId: '',
        selectedProductId: '',
        selectedRatingId: '',
        selectedName: '',
        isProductFocus: false,
        slug: '',
        completed: undefined
    },
    language: 'de',
    params: {
        currentSlug: '',
        currentPortalLang: 'at',
        currentRatingId: '',
        currentSlugPrefix: '',
        originPortalLang: '',
    },
    dataSlug: '',
    dataSlugPrefix : '',
    failure: false
}

// NOTE: 
// It is important to pass an initial state as default to 
// the state parameter to handle the case of calling 
// the reducers for the first time when the 
// state might be undefined

export const stateReducer = (state = globalState, action) => {
    console.log('triggered action')
    switch (action.type) {
        case "SET_DATA_SLUG":
            return {
                ...state,
                dataSlug: action.slug,
                dataSlugPrefix: action.slugPrefix
            };
        case "REMOVE_RATING_PARAM":
            return {
                ...state, 
                params: {
                    currentSlugPrefix: state.params.currentSlugPrefix,
                    currentSlug: state.params.currentSlug,
                    currentRatingId: undefined,
                    currentPortalLang: state.params.currentPortalLang,
                    originPortalLang: state.params.originPortalLang
                },
            };
        case "SET_SEARCH_TEXT":
            return {
                ...state,
                search: {
                    text: action.text
                },
            };
        case "SET_URL_PARAMS":
            let currentLang = state.language;
            if (action.portalLang === 'en')
                currentLang = 'en';
            else if (action.portalLang !== 'en')
                currentLang = 'de';
            return {
                ...state,
                params: {
                    currentSlugPrefix: action.slugPrefix,
                    currentSlug: action.slug,
                    currentRatingId: action.ratingId,
                    currentPortalLang: action.portalLang,
                    originPortalLang: state.params.originPortalLang
                },
                language: currentLang
            };
        case "SET_SLUG":
            return {
                ...state,
                params: {
                    currentSlugPrefix: action.slugPrefix,
                    currentSlug: action.slug,
                }
            };
        case "SET_FAILURE":
            return {
                ...state,
                failure: action.failure
            };
        case "NEW_RATING_SELECTED":
            return {
                ...state,
                params: {
                    currentSlugPrefix: state.params.currentSlugPrefix,
                    currentSlug: state.params.currentSlug,
                    currentPortalLang: state.params.currentPortalLang,
                    currentRatingId: action.ratingId,
                    originPortalLang: state.params.originPortalLang
                }
            };
        case "SET_SLUG_STATE":
            console.log('setting slug state' + JSON.stringify(state))
            return {
                ...state,
                data: {
                    selectedProductId: state.data.selectedProductId,
                    isProductFocus: state.data.isProductFocus,
                    selectedAccountId: state.data.selectedAccountId,
                    selectedRatingId: action.ratingId,
                    selectedLanguage: state.data.selectedLanguage,
                    selectedName: state.data.selectedName,
                    slug: state.data.slug,
                },
                params: {
                    currentPortalLang: action.lang,
                    currentRatingId: action.ratingId,
                    currentSlug: action.slug,
                    currentSlugPrefix: action.slugPrefix
                }
            }
        case "SET_AUTOCOMPLETE_RESULT":
            return {
                ...state,
                data: {
                    selectedAccountId: action.accountId,
                    selectedProductId: action.productId,
                    selectedRatingId: action.ratingId,
                    selectedName: action.name,
                    isProductFocus: action.isProductReference,
                    slug: action.slug,
                    completed: action.completed
                },
                params: {
                    currentPortalLang: state.params.currentPortalLang,
                    currentRatingId: state.params.currentRatingId,
                    currentSlug: action.slug.split('/')[1],
                    currentSlugPrefix: action.slug.split('/')[0],
                }
            };
        case "REMOVE_PRODUCT_FOCUS":
            return {
                ...state,
                data: {
                    selectedProductId: '',
                    isProductFocus: false,
                    selectedAccountId: state.data.selectedAccountId,
                    selectedRatingId: state.data.selectedRatingId,
                    selectedLanguage: state.data.selectedLanguage,
                    selectedName: state.data.selectedName,
                    slug: action.slug,
                },
                params: {
                    currentPortalLang: state.params.currentPortalLang,
                    currentRatingId: state.params.currentRatingId,
                    currentSlug: action.slug.split('/')[1],
                    currentSlugPrefix: action.slug.split('/')[0],
                }
            };
        case "SET_LANGUAGE":
            console.log('set language: ' + JSON.stringify(state))
            let currentParamLang = state.params.currentPortalLang;
            if (action.lang === 'en')
                currentParamLang = 'en';
            return {
                ...state,
                language: action.lang,
                params: {
                    currentPortalLang: currentParamLang,
                    currentSlug: state.params.currentSlug,
                    currentRatingId: state.params.currentRatingId,
                    currentSlugPrefix: state.params.currentSlugPrefix,
                    originPortalLang: state.params.originPortalLang
                }
            };
        case "SET_DATA":
            return {
                ...state,
                data: {
                    selectedAccountId: action.accountId,
                    selectedRatingId: action.ratingId,
                    selectedLanguage: action.language,
                    selectedProductId: action.productId,
                },
            };
        default:
            return state
    }
}       

import { Home } from "./components/Home";
import { NotFound } from "./components/main/utils/NotFound";

const AppRoutes = [
    {
        index: true,
        element: <Home />,

    },
    {
        path: '/:portalLang/:slugPrefix/:slug',
        element: <Home />,
        exact: true
    },
    {
        path: '/:portalLang/:slugPrefix/:slug/r/:ratingId',
        element: <Home />,
        exact: true
    },
    {
        path: '/:portalLang',
        element: <Home />,
        exact: true
    },
    {
        path: '/:portalLang/r/:ratingId',
        element: <Home />, 
        exact: true
    },
    {
        path: '*',
        element: <NotFound />,
    }
];

export default AppRoutes;

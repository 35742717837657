import React, { Component } from 'react';
import { Pagination, PaginationItem, PaginationLink, Badge, InputGroup, InputGroupText, Button, Input, TabPane, Row, Col } from 'reactstrap';
import './product.css'
import { Avatar } from '../products/avatar/Avatar'
import { WidgetModal } from '../widget/modal/WidgetModal';
import { useParams, useNavigate } from "react-router-dom";
import { storeProvider } from '../../../store/storeProvider';
import utils from '../../../utils/utils'
function withParams(Component) {
    return props => <Component {...props} params={useParams()} history={useNavigate()} />;
}

class Product extends Component {

    static currentStore;

    constructor() {
        super();
        this.currentStore = storeProvider.getStore();
        this.state = {
            products: [],
            accountSlug: '', 
            ogProducts: [],
            filteredProducts: [],
            mounted: false,
            currentPage: 1,
            pagesTotal: 0,
            searchText: '',
            currentSorting: "years",
            timeoutId: ''
        };
        this.renderProductPage = this.renderProductPage.bind(this);
        this.paginate = this.paginate.bind(this);
        this.incrementPage = this.incrementPage.bind(this);
        this.decrementPage = this.decrementPage.bind(this);
        this.renderPaginationBar = this.renderPaginationBar.bind(this);
        this.renderPaginationLogic = this.renderPaginationLogic.bind(this);
        this.changeSorting = this.changeSorting.bind(this);
        this.reverseSort = this.reverseSort.bind(this);
        this.searchProduts = this.searchProduts.bind(this);
        this.renderSearchBar = this.renderSearchBar.bind(this);
        this.getPulsatingClassName = this.getPulsatingClassName.bind(this);
        this.renderModal = this.renderModal.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
    }

    paginate(pageNumber, array = [], productId = null) {
        if (pageNumber <= 0 && array === []) {
            this.setState({
                currentPage: 1
            });
            return;
        }
        if (pageNumber > this.state.pagesTotal && array.length === 0) {
            this.setState({
                currentPage: this.state.pagesTotal
            });
            return;
        }
        let allItems = this.state.products;
        if (this.state.products == null || this.state.products.length === 0)
            allItems = array;
        let pageSize = 20;
        let totalPages = Math.ceil(allItems.length / pageSize);
        console.log(pageSize);
        // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
        let filteredItems = allItems.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
        this.setState({
            filteredProducts: filteredItems,
            currentPage: pageNumber,
            pagesTotal: totalPages
        })
        this.forceUpdate();
        if (productId !== undefined && productId !== null) {
            setTimeout(() => {
                let stateProducts = filteredItems.filter(t => t.productId === productId);
                if (stateProducts.length === 0)
                    return;
                let stateProduct = stateProducts[0];
                if (stateProduct === undefined)
                    return;
                stateProduct.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 1000);
        }
    }
    incrementPage() {
        let currentPage = this.state.currentPage;
        this.paginate(currentPage + 1);
    }
    decrementPage() {
        let currentPage = this.state.currentPage;
        if (currentPage === 1)
            return;
        this.paginate(currentPage - 1);
    }
    reverseSort() {
        this.setState({
            products: this.state.products.reverse(),
        });
        this.paginate(1);
    }
    searchProduts(value) {
        let filteredItems = this.state.ogProducts;
        if (value !== '' && value !== undefined && value !== null) {
            filteredItems = this.state.ogProducts.filter(g => g.productName.toLowerCase().includes(value.toLowerCase()) || g.year.includes(value));
        }

        this.setState({
            products: filteredItems,
            searchText: value
        });
        if (this.state.timeoutId !== '') {
            clearTimeout(this.state.timeoutId);
        }
        let timeoutFunction = setTimeout(() => {
            this.paginate(1, filteredItems);
        }, 250);
        this.setState({
            timeoutId: timeoutFunction
        });

        return;
    }

    changeSorting(valu) {
        if (valu === "years") {
            this.setState({
                products: this.state.products.sort((a, b) => Number(b.year) - Number(a.year)),
                ogProducts: this.state.ogProducts.sort((a, b) => Number(b.year) - Number(a.year)),
                currentSorting: "years"
            });
        }
        if (valu === "points") {
            this.setState({
                products: this.state.products.sort((a, b) => Number(b.ratingPointsTotal) - Number(a.ratingPointsTotal)),
                ogProducts: this.state.ogProducts.sort((a, b) => Number(b.ratingPointsTotal) - Number(a.ratingPointsTotal)),
                currentSorting: "points"
            });
        }
        this.paginate(1);
    }
    componentDidMount() {
        if (this.state.mounted === true)
            return;
        this.setState({
            products: this.props.data,
            ogProducts: this.props.data,
            mounted: true,
            focus: this.props.focus,
            accountSlug: this.props.accountSlug
        });
        if (this.props.focus !== null && this.props.focus !== undefined && this.props.focus.hasFocus === true) {
            let affectedProduct = this.props.data.filter(t => t.productId === this.props.focus.productId);
            if (affectedProduct.length === 0) {
                this.paginate(1, this.props.data);
                return;
            }
            let index = this.props.data.indexOf(affectedProduct[0]);
            let theoreticalPage = Math.ceil(index / 20);

            if (theoreticalPage <= 0) {
                this.paginate(1, this.props.data);
                return;
            }
            if (index % 10 === 0)
                theoreticalPage = theoreticalPage + 1;
            this.paginate(theoreticalPage, this.props.data, affectedProduct[0].productId);

        }
        else {
            this.paginate(1, this.props.data);
        }

    }


    renderPaginationBar(classNames) {
        return (
            <div className={classNames} style={{ fontSize: "small" }} >
                <div className="col-lg-4 col-md-4 col-sm-0 pt-2 d-none d-sm-block" style={{ 'font-family': 'Barlow,sans-serif' }}>
                    <p>{this.state.currentPage === 1 ? 1 : (this.state.currentPage - 1) * 20} - {this.state.currentPage * 20 > this.state.products.length ? this.state.products.length : this.state.currentPage * 20} von {this.state.products.length} Einträgen</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <Pagination size="sm" className="justify-content-center">
                        <PaginationItem disabled={this.state.currentPage === 1}>
                            <PaginationLink
                                style={{ backgroundColor: '#333333', color: 'white' }}
                                onClick={() => this.paginate(1)}
                                first
                            />
                        </PaginationItem>
                        <PaginationItem disabled={this.state.currentPage === 1}>
                            <PaginationLink
                                onClick={() => this.decrementPage()}
                                style={{ backgroundColor: '#333333', color: 'white' }}
                                previous
                            />
                        </PaginationItem>
                        {
                            this.renderPaginationLogic()
                        }
                        <PaginationItem disabled={this.state.currentPage === (this.state.pagesTotal)}>
                            <PaginationLink
                                onClick={() => this.incrementPage()}
                                style={{ backgroundColor: '#333333', color: 'white' }}
                                next
                            />
                        </PaginationItem>
                        <PaginationItem disabled={this.state.currentPage === (this.state.pagesTotal)}>
                            <PaginationLink
                                onClick={() => this.paginate(this.state.pagesTotal)}
                                style={{ backgroundColor: '#333333', color: 'white' }}
                                last
                            />
                        </PaginationItem>
                    </Pagination>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-0 justify-content-end d-none d-sm-block " style={{ float: 'right' }}>
                    <InputGroup className="justify-content-end" size="sm" style={{ float: 'right' }}>
                        <InputGroupText style={{ backgroundColor: 'unset', border: 'unset', 'font-family': 'Barlow,sans-serif' }} >
                            <div className="hoverable-item" onClick={() => this.reverseSort()}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="mask0_6525_61817" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                        <rect width="24" height="24" fill="#D9D9D9" />
                                    </mask>
                                    <g mask="url(#mask0_6525_61817)">
                                        <path d="M14.9996 21.65L10.3496 17L11.4246 15.95L14.2496 18.775V11.25H15.7496V18.775L18.5746 15.95L19.6496 17L14.9996 21.65ZM8.24961 12.75V5.22498L5.42461 8.04998L4.34961 6.99998L8.99961 2.34998L13.6496 6.99998L12.5746 8.04998L9.74961 5.22498V12.75H8.24961Z" fill="black" fill-opacity="0.8" />
                                    </g>
                                </svg>
                            </div>

                            Sortiert nach
                        </InputGroupText>
                        <select style={{ 'font-family': 'Barlow,sans-serif' }} className="" value={this.state.currentSorting} onChange={(event) => this.changeSorting(event.target.value)}>
                            <option value="years">Jahrgang</option>
                            <option value="points">Punkte</option>
                        </select>
                    </InputGroup>
                </div>
            </div>
        );
    }
    renderPaginationLogic() {
        return (
            <PaginationItem>
                <div className="row text-center">
                    <div className="col-12 pb-4" style={{ 'align-self': "center" }} >
                        <InputGroup size="sm">
                            <InputGroupText style={{ backgroundColor: 'unset', border: 'unset', 'font-family': 'Barlow,sans-serif' }} >
                                Seite
                            </InputGroupText>
                            <input type="number" min="1" max={this.state.pagesTotal} value={this.state.currentPage} style={{ paddingRight: 'unset', 'font-family': 'Barlow,sans-serif' }} onChange={(event) => this.paginate(Number(event.target.value))} className="form-control"></input>
                            <InputGroupText style={{ backgroundColor: 'unset', border: 'unset', 'font-family': 'Barlow,sans-serif' }}>
                                von {this.state.pagesTotal}
                            </InputGroupText>
                        </InputGroup>


                    </div>
                </div>
            </PaginationItem >

        );
    }

    renderModal(product, widget) {
        this.widgetModal.showModal(product.widgets, (product.year !== null ? product.year + " " + product.productName : product.productName), widget)
        if (product.slug !== null) {
            let urlParams = this.currentStore.getState().params;
            let portalLang = urlParams.currentPortalLang === undefined ? 'at' : urlParams.currentPortalLang;
            utils.pushUrl(portalLang, product.slug.split('/')[0], product.slug.split('/')[1], undefined);
        }
    }

    handleModalClose() {
        let urlParams = this.currentStore.getState().params;
        let portalLang = urlParams.currentPortalLang === undefined ? 'at' : urlParams.currentPortalLang;
        let ratingId = urlParams.currentRatingId;
        utils.pushUrl(portalLang, this.currentStore.getState().dataSlugPrefix, this.currentStore.getState().dataSlug, ratingId);

    }

    renderSearchBar() {
        return (
            <div className="row pb-4 pt-2">
                <div className="col-12" style={{ paddingLeft: "20px" }}>
                    <div className="button-grouped-solo" style={{ backgroundColor: 'white' }}>
                        <Input style={{ 'font-family': 'Barlow,sans-serif' }} defaultValue={this.props.searchText} value={this.state.searchText} onChange={event => this.searchProduts(event.target.value)} className="input-rounded" placeholder="Produkte durchsuchen">
                        </Input>
                    </div>
                </div>
            </div>
        );
    }

    getPulsatingClassName(product) {
        if (product.productId === this.state.focus.productId) {
            setTimeout(() => {
                this.setState({
                    focus: {
                        productId: ''
                    }
                });
            }, 3000);
            return (" row pt-1 pulsate-product");
        }
        else
            return ("row pt-1");
    }

    renderProductPage() {
        if (this.state.filteredProducts == null || this.state.filteredProducts.length === 0) {
            return (
                <div>
                    {
                        this.renderSearchBar()
                    }
                    <div className="row pt-5 pb-5">
                        <div className="col-12 text-center">
                            <p>Keine Ergebnisse gefunden</p></div>
                    </div>

                </div>);
        }

        return (
            <div>
                {
                    this.renderSearchBar()
                }
                {
                    this.renderPaginationBar("row pt-1 pb-2")
                }
                <div className="row" style={{ display: 'contents' }}>
                    <table className="table">
                        <tbody>
                            {
                                this.state.filteredProducts.map((product, i) => {
                                    return (
                                        <tr>
                                            <td>

                                                <div className={this.getPulsatingClassName(product)} ref={product}>
                                                    <div className="col-lg-10 col-md-10 col-sm-12">
                                                        <div className="pb-2"><h4>{product.year} {product.productName}</h4></div>
                                                        <div className="row">
                                                            {
                                                                product.widgets.map((widget, i) => {
                                                                    return (
                                                                        <div className="col-auto pt-2">
                                                                            <Badge
                                                                                color="secondary"
                                                                                pill
                                                                                className="productBadge"
                                                                                style={{ 'font-family': 'Barlow,sans-serif' }}
                                                                                onClick={event => this.renderModal(product, widget)}
                                                                            >
                                                                                <span>
                                                                                    {
                                                                                        widget.widgetFormatLabel
                                                                                    }
                                                                                </span>
                                                                            </Badge>
                                                                        </div>

                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-2 col-sm-0 d-none d-sm-block">
                                                        <Avatar data={product}></Avatar>
                                                    </div>
                                                </div>
                                            </td>

                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                {
                    this.renderPaginationBar("row pt-1 pb-4")
                }
                <WidgetModal ref={widgetModal => this.widgetModal = widgetModal} callback={this.handleModalClose.bind(this)}></WidgetModal>
            </div >
        )

    }

    render() {
        return (
            this.renderProductPage()
        );
    }
}
export default withParams(Product);
import React, { Component, useState } from 'react';
import { FormGroup, Modal, ModalHeader, ModalBody, InputGroup, InputGroupText, Input, Label } from 'reactstrap';
import logo from '../../../resources/scss/static/assets/logo.svg';
import { Spinner } from '../utils/Spinner'
import './downloadmodal.css'
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export class DownloadModal extends Component {
    constructor() {
        super();
        this.state = {
            displayModal: false,
            accountName: '',
            isLoading: false,
            email: '',
            agbAccepted: false,
            error: false, 
            success: false,
            accountId: ''
        };
        this.toggleModal = this.toggleModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.downloadMaterial = this.downloadMaterial.bind(this);
    }

    showModal(accountName, accountid) {
        this.setState({
            displayModal: true,
            accountName: accountName,
            isLoading: false,
            success: false,
            accountId: accountid,
            error: false, 
            agbAccepted: false,
        });
    }

    toggleModal() {
        let display = this.state.displayModal;
        this.setState({
            displayModal: !display
        });
    }

    async download() {
        toast.info('Ihre Anfrage wird erstellt...');
        let resp = await fetch('/api/download/account/' + this.state.accountId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: this.state.email })
        });
        let json = await resp.json();
        return json;
    }




    async downloadMaterial() {
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email) === false) {
            toast.error('Bitte geben Sie eine gültige Email Adresse an', { containerId: 'DOWNLOAD' });
            return;
        }
        this.setState({
            isLoading: true
        });
        let ans = await this.download();
        if (ans.success === true) {
            setTimeout(() => {
                toast.success('Ihre Anfrage wurde erfolgreich erstellt!', { containerId: 'DOWNLOAD' });
                this.setState({
                    success: true,
                    isLoading: false
                });
            }, 1600);
        }
        else {
            setTimeout(() => {
                toast.error('Beim Erstellen der Anfrage ist ein Fehler aufgetreten!', { containerId: 'DOWNLOAD' });
                this.setState({
                    success: false,
                    isLoading: false,
                    error: true,
                });
            }, 1600);
        }


        return;
    }


    renderMainContent() {
        if (this.state.isLoading) {
            return (
                <ModalBody>
                    <Spinner></Spinner>
                </ModalBody>
            )
        }
        if (this.state.success) {
            return (
                <ModalBody style={{ 'font-family': 'Barlow,sans-serif' }}>
                    <div className="row">
                        <p>Vielen Dank für Ihre Angaben!
                            <br></br>
                            <br></br>
                            Wir möchten Sie informieren, dass wir Ihre Anfrage erfolgreich erhalten haben. In Kürze erhalten Sie eine Bestätigungs-E-Mail von uns als Nachweis Ihrer eingereichten Anfrage. Bitte überprüfen Sie Ihren Posteingang (und gegebenenfalls Ihren Spam-Ordner), um sicherzustellen, dass Sie die E-Mail erhalten.
                            <br></br>
                            <br></br>
                            Wir bedanken uns für Ihr Interesse und werden Ihre Anfrage so schnell wie möglich bearbeiten.
                            <br></br>
                            <br></br>
                            Herzliche Grüße,
                            <br></br>
                            Ihr Falstaff Team
                        </p>
                    </div>
                </ModalBody>);
        }
        else if (this.state.error === true) {
            return (
                <ModalBody style={{ 'font-family': 'Barlow,sans-serif' }}>
                    <div className="row">
                        <p>Leider konnten wir Ihre Anfrage aufgrund eines technischen Fehlers nicht erfolgreich verarbeiten. Wir entschuldigen uns für die Unannehmlichkeiten und bitten Sie, es zu einem späteren Zeitpunkt noch einmal zu versuchen. Unser Team arbeitet bereits daran, das Problem zu beheben, um sicherzustellen, dass Ihr nächster Versuch reibungslos verläuft.
                            <br></br>
                            <br></br>
                            Vielen Dank für Ihr Verständnis und Ihre Geduld.
                            <br></br>
                            <br></br>
                            Falls Sie weitere Unterstützung benötigen, zögern Sie bitte nicht, uns zu kontaktieren.
                            <br></br>
                            <br></br>
                            Herzliche Grüße,
                            <br></br>
                            Ihr Falstaff Team
                        </p>
                    </div>
                </ModalBody>);
        }
        else {
            return (
                <ModalBody style={{ 'font-family': 'Barlow,sans-serif' }}>
                    <div className="row">
                        <p>Sehr geehrte Nutzerin, sehr geehrter Nutzer,
                            <br></br>
                            <br></br>
                            wir möchten Sie darüber informieren, dass der Prozess des Herunterladens sämtlichen Promotionsmaterials einige Zeit in Anspruch nimmt. Um sicherzustellen, dass Sie Ihr personalisiertes Paket so schnell und effizient wie möglich erhalten, benötigen wir Ihre E-Mail-Adresse.
                            <br></br>
                            <br></br>
                            Sobald Ihr Promotionsmaterial fertiggestellt und bereit zum Herunterladen ist, senden wir Ihnen einen exklusiven Link per E-Mail. Über diesen Link können Sie dann ganz bequem Ihr maßgeschneidertes Paket herunterladen.
                            <br></br>
                            <br></br>
                            Wir danken Ihnen für Ihr Verständnis und freuen uns darauf, Ihnen unser hochwertiges Promotionsmaterial zur Verfügung zu stellen.</p>
                    </div>
                    <div className="row pt-5 pb-2">
                        <FormGroup>
                            <Label for="exampleEmail">
                                Ihre Email Adresse
                            </Label>
                            <Input
                                id="exampleEmail"
                                name="email"
                                type="email"
                                value={this.state.email}
                                onChange={(event) => {
                                    this.setState({ email: event.target.value });
                                }}
                            />
                        </FormGroup>
                    </div>
                    <div className="row pt-1 pb-5">
                        <div className="col-12">
                            <FormGroup check>
                                <Input type="checkbox" checked={this.state.agbAccepted} onChange={(event) => { this.setState({ agbAccepted: event.target.checked }); }} />
                                {' '}
                                <Label check>
                                    <p style={{ paddingLeft: '5px' }}>Ich stimme zu: Mit dem Setzen dieses Häkchens bestätige ich, dass ich die <a className="extern-link" target="_blank" rel="noopener noreferrer" href="https://www.falstaff.com/at/agb-at">Allgemeinen Geschäftsbedingungen (AGBs)</a>  und die <a className="extern-link" target="_blank" rel="noopener noreferrer" href="https://www.falstaff.com/at/datenschutz-at">Datenschutzrichtlinie</a> gelesen habe und diesen zustimme. Ich verstehe, dass meine personenbezogenen Daten gemäß der Datenschutzrichtlinie verarbeitet werden und dass die AGBs die Nutzung der Dienste regeln. Diese Zustimmung ist notwendig, um fortzufahren und unsere Dienste in Anspruch nehmen zu können.</p>

                                </Label>
                            </FormGroup>
                        </div>
                    </div>
                    {
                        this.state.email !== '' && this.state.agbAccepted === true ?
                            (
                                <div className="row pt-1 pb-5 justify-content-center">
                                    <div className="col-4 justify-content-center">
                                        <button onClick={() => this.downloadMaterial()} type="button" className="button button--primary button--largejustify-content-end">
                                            Promotion Material anfordern
                                        </button>
                                    </div>
                                </div>
                            ) :
                            (
                                <div></div>
                            )
                    }

                </ModalBody>
            )
        }
    }
    render() {
        return (
            <div>
                <ToastContainer
                    enableMultiContainer
                    containerId={'DOWNLOAD'}
                    position="top-center"
                    autoClose={1500}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    pauseOnHover={false}
                    theme="light"
                    style={{ 'font-family': 'Barlow,sans-serif' }}
                />
                <Modal fullscreen="xl" size="xl" backdrop="true" fade="true" scrollable="true" centered="true" isOpen={this.state.displayModal} toggle={this.toggleModal}>
                    <div className="row">
                        <div className=" col-12 pt-4 pb-4" style={{ "background-color": "rgb(245, 245, 245)" }}>
                            <ModalHeader toggle={this.toggleModal}>
                                <div className="row justify-content-center">
                                    <div className="col-md-auto text-center align-self-center">
                                        <img height={48} width={161} className="inverted" src={logo}></img>
                                    </div>
                                </div>
                                <div className="row pt-4 justify-content-center">
                                    <div className="col-md-auto text-center">
                                        Promotion Material für {this.state.accountName} herunterladen
                                    </div>
                                </div>
                            </ModalHeader>
                            {
                                this.renderMainContent()
                            }
                        </div>
                    </div>
                </Modal >
            </div>

        );
    }
}
import React, { useEffect, useState, useRef } from 'react';
import { Spinner } from './main/utils/Spinner'
import { NotFound } from './main/utils/NotFound'
import { WidgetTab } from './main/tabs/WidgetTab'
import { Header } from './header/Header';
import { WidgetHeader } from './main/header/WidgetHeader';
import { WelcomeDe } from './WelcomeDe';
import { WelcomeEn } from './WelcomeEn';
import { useParams } from "react-router-dom";
import { storeProvider } from '../store/storeProvider';
import utils from '../utils/utils.js'
import { useSelector } from 'react-redux';


export function Home() {
    //const displayName = Home.name;
    //const currentStore = {};
    //const currentStoreSubscription = {};
    //const currentAffectedAccountId = '';

    //function handlePopState() {
    //    let urlParams = this.props.params;
    //    this.currentStore.dispatch({
    //        type: 'SET_URL_PARAMS',
    //        slug: urlParams['slug'] ?? null,
    //        portalLang: urlParams['portal_lang'] ?? 'at',
    //        ratingId: urlParams['rating_id'] ?? null
    //    })
    //}Home

    const [currentWidth, setWidth] = useState([
        window.innerWidth
    ]);

    const firstRender = useRef(true);

    const {
        portalLang = 'at',
        slug,
        slugPrefix,
        ratingId
    } = useParams();

    const [state, setState] = useState({
        jsonResponse: '',
        accountId: '',
        isLoading: false,
        data: {},
        ratingFocus: {
            hasFocus: false,
            ratingId: ''
        },
        params: {
            currentPortalLang: portalLang,
            currentSlug: slug,
            currentSlugPrefix: slugPrefix,
            currentRatingId: ratingId
        },
        language: portalLang === 'en' ? 'en' : 'de',
        productFocus: {
            hasFocus: false,
            productId: ''
        }
    });

    useEffect(() => {
        if (firstRender.current === false)
            return;
        storeProvider.getStore().dispatch({
            type: 'SET_SLUG_STATE',
            slugPrefix: slugPrefix,
            slug: slug,
            ratingId: ratingId,
            portalLang: portalLang === undefined || portalLang === '' ? 'at' : portalLang,
            originPortalLang: portalLang === undefined || portalLang === '' ? 'at' : portalLang
        });
        storeProvider.getStore().dispatch({
            type: 'SET_LANGUAGE',
            lang: portalLang === 'en' ? 'en' : 'de',
        });
    }, [])





    const accountIdSelector = useSelector((state) => state.data.selectedAccountId);

    async function fetchDataById(accountId) {
        if (accountId === undefined || accountId === '')
            return;
        setState({ isLoading: true, data: {} });
        let appSt = storeProvider.getStore().getState();
        if (appSt.data.selectedRatingId === undefined && ratingId !== undefined)
            appSt.data.selectedRatingId = ratingId;
        let currentPortalLang = appSt.params.currentPortalLang;
        if (currentPortalLang === undefined) {
            currentPortalLang = portalLang;
        }
        let widgetData = await utils.fetchWidgetData(accountId, appSt.language, currentPortalLang);
        setState({
            jsonResponse: JSON.stringify(widgetData, null, 2),
            isLoading: false,
            selectedLanguage: appSt.language,
            data: widgetData,
            accountId: accountId,
            productFocus: {
                hasFocus: appSt.data.isProductFocus,
                productId: appSt.data.selectedProductId
            },
            ratingFocus: {
                hasFocus: appSt.params.currentRatingId !== undefined && appSt.params.currentRatingId !== '',
                ratingId: appSt.params.currentRatingId
            }
        })
        storeProvider.getStore().dispatch({
            type: 'SET_DATA_SLUG',
            slugPrefix: widgetData.slug.split('/')[0],
            slug: widgetData.slug.split('/')[1],
        });
        const nw_urlSegments = window.location.pathname.split('/');
        const nw_lang = nw_urlSegments[1];
        const nw_prefix = widgetData.slug.split('/')[0];
        const nw_slug = widgetData.slug.split('/')[1];
        let nw_ratingId = undefined;
        if (nw_urlSegments.length > 4) {
            nw_ratingId = nw_urlSegments[nw_urlSegments.length - 1];
        }
        let nw_newUrl = "/" + nw_lang + "/" + nw_prefix + "/" + nw_slug;
        if (nw_ratingId !== undefined)
            nw_newUrl = nw_newUrl + "/r/" + nw_ratingId;
        window.history.pushState({}, "", nw_newUrl);
    }

    useEffect(() => {
        if (firstRender.current === true)
            return;
        fetchDataById(accountIdSelector);
    }, [accountIdSelector])


    const paramsSelector = useSelector((state) => state.params)

    useEffect(() => {
        if (firstRender.current === true)
            return;
        updateUrl(paramsSelector);
    }, [paramsSelector])

    const langSelector = useSelector((state) => state.language)
    useEffect(() => {
        if (firstRender.current === true)
            return;
        console.log('URL UPDATE TRIGGERED: ' + langSelector);
        let toQuery = langSelector;
        let originLang = storeProvider.getStore().getState().params.originPortalLang;
        if (toQuery !== 'en')
            toQuery = originLang === undefined || originLang === '' ? 'at' : originLang;
        let obj = storeProvider.getStore().getState().params;
        obj.currentPortalLang = toQuery;
        updateUrl(obj);
        if (storeProvider.getStore().getState().data.selectedAccountId !== '') {
            fetchDataById(storeProvider.getStore().getState().data.selectedAccountId);
        }
    }, [langSelector])

    function updateUrl(params) {
        console.log('current portallang: ' + portalLang)
        if (params.currentPortalLang === undefined)
            params.currentPortalLang = portalLang;
        //if (params.currentRatingId === undefined)
        //    params.currentRatingId = ratingId;
        utils.pushUrl(params.currentPortalLang, params.currentSlugPrefix, params.currentSlug, params.currentRatingId);
    }

    const ratingSelector = useSelector((state) => state.params.currentRatingId);

    useEffect(() => {
        if (firstRender.current === true)
            return;
        if (ratingSelector === undefined || ratingSelector === '')
            return;
        updateSelectedRating(ratingSelector);
    }, [ratingSelector]);


    function updateSelectedRating(id) {
        state.productFocus = {
            hasFocus: false,
            productId: ''
        };
        state.ratingFocus = {
            hasFocus: true,
            ratingId: id
        };
        setState(state);
    }

    const completedSelector = useSelector((state) => state.data.completed)

    useEffect(() => {
        if (firstRender.current === true)
            return;
        updateBySelection(completedSelector);
    }, [completedSelector])

    async function updateBySelection(time) {
        if (time === undefined)
            return;
        let data = await utils.fetchSlugData(storeProvider.getStore().getState().params.currentPortalLang, storeProvider.getStore().getState().params.slug, storeProvider.getStore().getState().params.currentRatingId);
        storeProvider.getStore().dispatch({
            type: 'SET_AUTOCOMPLETE_RESULT',
            accountId: data.accountId,
            productId: data.productId,
            name: data.accountName,
            isProductReference: data.isProductReference,
            ratingId: data.ratingId ?? '',
            slug: data.slug,
            completed: undefined
        });
        storeProvider.getStore().dispatch({
            type: 'SET_SLUG_STATE',
            slugPrefix: data.slug.split('/')[0],
            slug: data.slug.split('/')[1],
            ratingId: data.ratingId ?? '',
            portalLang: storeProvider.getStore().getState().params.currentPortalLang
        });
    }



    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
        }
        if (slug !== undefined || ratingId !== undefined) {
            console.log('init called')
            initializeStuff();
        }

    }, [])
    async function initializeStuff() {
        let data = await utils.fetchSlugData(portalLang, slug, ratingId);
        storeProvider.getStore().dispatch({
            type: 'SET_AUTOCOMPLETE_RESULT',
            accountId: data.accountId,
            productId: data.productId,
            name: data.accountName,
            isProductReference: data.isProductReference,
            ratingId: data.ratingId ?? '',
            slug: data.slug,
            completed: undefined
        });
        storeProvider.getStore().dispatch({
            type: 'SET_SLUG_STATE',
            slugPrefix: slugPrefix,
            slug: slug,
            ratingId: ratingId,
            portalLang: portalLang === undefined || portalLang === '' ? 'at' : portalLang,
            originPortalLang: portalLang === undefined || portalLang === '' ? 'at' : portalLang
        });
    }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


    return (
        <div>
            <Header currentWidth={currentWidth} selectedAccountName={storeProvider.getStore().getState()?.data?.selectedName ?? ''} language={storeProvider.getStore().getState().language ?? 'de'}>
            </Header>
            <div className="container-fluid pt-4">
                <section className="bg-white">
                    <div>

                        {
                            (storeProvider.getStore().getState() === undefined || storeProvider.getStore().getState().data === undefined || storeProvider.getStore().getState().data.selectedAccountId === undefined || storeProvider.getStore().getState().data.selectedAccountId === '') ?
                                (
                                    storeProvider.getStore().getState().language === 'de' ?
                                        <WelcomeDe></WelcomeDe>
                                        :
                                        <WelcomeEn></WelcomeEn>
                                ) :
                                (
                                    state.isLoading === true || state.accountId === '' ?
                                        (
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-1 col-xs-1">
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-sm-10 col-xs-10">
                                                    <div style={{ "minHeight": "80vw", 'font-family': 'Barlow,sans-serif' }}>
                                                        <Spinner></Spinner>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-2 col-sm-1 col-xs-1">
                                                </div>
                                            </div>
                                        ) :
                                        (
                                            state.data.type === "FAILURE" ? (
                                                <div className="row">
                                                    <div className="col-lg-2 col-md-2 col-sm-1 col-xs-1">
                                                    </div>
                                                    <div className="col-lg-8 col-md-8 col-sm-10 col-xs-10">
                                                        <NotFound></NotFound>
                                                    </div>
                                                    <div className="col-lg-2 col-md-2 col-sm-1 col-xs-1">
                                                    </div>
                                                </div>

                                            ) :
                                                (
                                                    <div className="row">
                                                        <div className="col-lg-2 col-md-2 col-sm-1 col-xs-1">
                                                        </div>
                                                        <div className="col-lg-8 col-md-8 col-sm-10 col-xs-10">
                                                            <div>
                                                                <WidgetHeader data={state.data} accountId={state.accountId}></WidgetHeader>
                                                                <WidgetTab currentWidth={currentWidth} params={storeProvider.getStore().getState().params} data={state.data} ratingFocus={state.ratingFocus} productFocus={state.productFocus}></WidgetTab>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2 col-sm-1 col-xs-1">
                                                        </div>
                                                    </div>
                                                )
                                        )
                                )
                        }
                    </div>
                </section>
            </div>
        </div>


        //<section className="bg-white">
        //    <div>
        //        {
        //            this.state.isLoading ? (
        //                <div className="row">
        //                    <div className="col-2">
        //                    </div>
        //                    <div className="col-8">
        //                        <div style={{ "minHeight": "80vw", 'font-family': 'Barlow,sans-serif' }}>
        //                            <Spinner></Spinner>
        //                        </div>
        //                    </div>
        //                    <div className="col-2">
        //                    </div>
        //                </div>

        //            ) : (
        //                Object.keys(this.state.data).length === 0 ? (
        //                    <WelcomeDe></WelcomeDe>


        //                    )

        //            )
        //        }

        //    </div>
        //</section>

    );

}

class utils {
    static async fetchSlugData(portalLang, slug, ratingId) {
        if (slug === '' || slug === undefined)
            return;
        let url = '/api/data/account/slug?portallang=' + portalLang;
        if (slug !== '')
            url = url + '&slug=' + slug;
        if (ratingId !== '')
            url = url + '&ratingid=' + ratingId;
        let response = await fetch(url);
        let json = await response.json();
        return json;
    }

    static async fetchWidgetData(accountId, language, portalLang) {
        let response = await fetch('/api/data/account/' + accountId + '?lang=' + language + '&portallang=' + portalLang);
        let json = await response.json();
        return json;
    }

    static pushUrl(portalLang, slugPrefix, slug, ratingId) {
        console.log('pushing url: {1} {2} {3} {4}', portalLang, slugPrefix, slug, ratingId)
        if (portalLang === undefined || portalLang === '' || portalLang === null)
            portalLang = 'at';
        if (slugPrefix === undefined || slugPrefix === '' || slugPrefix === null)
            slugPrefix = undefined;
        if (slug === undefined || slug === '' || slug === null)
            slug = undefined;
        if (ratingId === undefined || ratingId === '' || ratingId === null)
            ratingId = undefined;
        let newUrl = '/' + portalLang;
        if (slugPrefix !== undefined && slug !== undefined)
            newUrl = newUrl + '/' + slugPrefix + '/' + slug;
        if (ratingId !== undefined)
            newUrl = newUrl + '/r/' + ratingId
        window.history.pushState({}, "", newUrl);
    }
}
export default utils;
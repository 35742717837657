import React, { Component } from 'react';
import './randomWidget.css'
export class RandomWidget extends Component {
    constructor() {
        super();
        this.state = {
            format: '',
            cdnUrl: '',
            fullHeight: true,
            type: '',
            lang: 'de'
        };
        this.renderWidget = this.renderWidget.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }


    componentDidMount() {
        this.setState({ format: this.props.format, fullHeight: this.props.fullHeight, type: this.props.type, lang: this.props.lang });
        this.fetchData(this.props.format, this.props.type, this.props.lang);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ format: nextProps.format, fullHeight: this.props.fullHeight, type: this.props.type, lang: this.props.lang });
    }

    fetchData = async (format, type, lang) => {
        let response = await fetch('/api/data/account/random?format=' + format + '&type=' + type + '&lang='+lang);
        let json = await response.json();
        this.setState({
            cdnUrl: json.cdnUrl
        });
    }
    renderWidget() {
        return (
            <div class="randomWidget" style={{ height: this.state.fullHeight ? 'auto' : '', width: 'auto' }}>
                <img src={this.state.cdnUrl} style={{ height: 'auto', width: 'auto' }}>
                </img>
            </div>
        )
    }

    render() {
        return this.renderWidget();
    }
} 
import React, { Component } from 'react';
import classnames from 'classnames';
import { Nav, NavItem, NavLink, Badge, TabContent, CardTitle, Button, CardText, TabPane, Row, Col } from 'reactstrap';
import './widgettab.css'
import { WidgetItem } from '../widget/WidgetItem'
import Product from '../products/Product.js'
import { useParams, useNavigate } from "react-router-dom";
import { storeProvider } from '../../../store/storeProvider';
import utils from '../../../utils/utils'
export class WidgetTab extends Component {
    static currentStore;

    constructor() {
        super();
        this.currentStore = storeProvider.getStore();
        this.state = {
            data: {
                ratingCategories: [],
                products: [],
                slug: '',
            },
            productFocus: {
                hasFocus: false,
                productId: ''
            },
            activeTab: '0'
        };
        this.toggle = this.toggle.bind(this);
    }


    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
            if (tab === 'products')
                return;
            let selectedElement = this.props.data.ratingCategories[Number.parseInt(tab)];
            let affectedRatingId = selectedElement.ratingId;
            if (this.props.data.ratingCategories.length > 1)
                this.currentStore.dispatch({
                    type: 'NEW_RATING_SELECTED',
                    ratingId: affectedRatingId
                });
            this.currentStore.dispatch({
                type: 'REMOVE_PRODUCT_FOCUS',
                slug: this.currentStore.getState().dataSlugPrefix + '/' + this.currentStore.getState().dataSlug
            });
            let urlParams = this.currentStore.getState().params;
            let portalLang = urlParams.currentPortalLang === undefined ? 'at' : urlParams.currentPortalLang;
            let ratingId = urlParams.currentRatingId;
            utils.pushUrl(portalLang, this.currentStore.getState().dataSlugPrefix, this.currentStore.getState().dataSlug, ratingId);
            this.setState({
                productFocus: {
                    hasFocus: false,
                    productId: ''
                },
            })
        }
    }
    componentDidMount() {
        if (this.props.productFocus.hasFocus === true) {
            this.setState({ data: this.props.data, productFocus: this.props.productFocus, activeTab: 'products' });
        }
        else if (this.props.ratingFocus.hasFocus === true) {
            console.log('widget tab');
            let affectedRating = this.props.ratingFocus.ratingId;
            let item = this.props.data.ratingCategories.filter(t => t.ratingId === affectedRating);
            let index = this.props.data.ratingCategories.indexOf(item[0]);
            this.setState({ data: this.props.data, activeTab: index.toString() });
        }
        else
            this.setState({ data: this.props.data });

        this.setState({
            params: this.props.params
        })
    }

    render() {
        return (
            <div>
                <Nav tabs style={{ gap: '5px' }}>
                    {
                        this.state.data.ratingCategories.map((category, i) => {
                            return (
                                <Badge
                                    color="secondary"
                                    pill
                                    className="pr-5"
                                    style={{ 'font-family': 'Barlow,sans-serif', cursor: 'pointer !important;' }}
                                >
                                    <NavLink
                                        style={{ cursor: 'pointer !important;' }}
                                        className={classnames({ active: this.state.activeTab === i.toString() })}
                                        onClick={() => { this.toggle(i.toString()); }}>
                                        {category.categoryName}
                                    </NavLink>
                                </Badge>
                            )
                        })
                    }
                    {
                        this.state.data.products != null && this.state.data.products.length > 0 ? (
                            <Badge

                                color="secondary"
                                pill
                                className="pr-5"
                                style={{ 'font-family': 'Barlow,sans-serif', cursor: 'pointer !important;' }}
                            >
                                <NavLink
                                    style={{ cursor: 'pointer !important;' }}
                                    className={classnames({ active: this.state.activeTab === 'products' || this.state.data.ratingCategories === null || this.state.data.ratingCategories.length === 0 })}
                                    onClick={() => { this.toggle('products'); }}>
                                    {this.state.data.productCategoryLabel}
                                </NavLink>
                            </Badge>
                        ) : ('')
                    }
                </Nav>
                <TabContent activeTab={this.state.data.ratingCategories === null || this.state.data.ratingCategories.length === 0 ? 'products' : this.state.activeTab}>
                    {
                        this.state.data.ratingCategories.map((category, i) => {
                            return (
                                <TabPane tabId={i.toString()} >
                                    {this.state.activeTab === i.toString() ? <WidgetItem accountName={this.state.data.accountName} data={category} currentWidth={this.props.currentWidth}></WidgetItem> : null}
                                </TabPane>
                            )
                        })
                    }
                    {
                        this.state.data.products != null && this.state.data.products.length > 0 && (this.state.activeTab === 'products' || this.state.data.ratingCategories === null || this.state.data.ratingCategories.length === 0) ? (
                            <div>
                                <hr />
                                <div className="pt-2 pb-4" style={{ 'font-family': 'Barlow,sans-serif' }}>  <h3>
                                    {this.state.data.productCategoryLabel}
                                </h3>
                                </div>
                                <TabPane tabId={'products'} >
                                    {this.state.activeTab === 'products' || this.state.data.ratingCategories === null || this.state.data.ratingCategories.length === 0 ? <Product accountSlug={this.state.data.slug} data={this.state.data.products} focus={this.state.productFocus} accountName={this.state.data.accountName}></Product> : null}
                                </TabPane>
                            </div>
                        ) : ('')
                    }
                </TabContent>
            </div>

        );
    }
}
import React, { Component } from 'react';
import './avatar.css'
export class Avatar extends Component {
    constructor() {
        super();
        this.state = {
            product: {}
        };
        this.renderAvatar = this.renderAvatar.bind(this);
        this.renderRatingPoints = this.renderRatingPoints.bind(this);
    }


    componentDidMount() {
        this.setState({ product: this.props.data });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ product: nextProps.data });
    }

    renderRatingPoints() {
        if (this.state.product.ratingPointsTotal === undefined || this.state.product.ratingPointsTotal === null)
            return;
        let points = this.state.product.ratingPointsTotal.toString();
        if (this.state.product.isPointsPlus === true) {
            points = points.split(',')[0].split('.')[0] + "+";
        }
        return points;
    }

    renderAvatar() {
        return (
            <div class="avatar">
                <div class="overlap">
                    <div class="bottle">
                        <img class="image" src={this.state.product.productImageUrl !== null && this.state.product.productImageUrl != "" ? this.state.product.productImageUrl : "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="} /></div>
                    <div class="points">
                        <div class="overlap-group">
                            <div class="inner-outline"></div>
                            <div class="element">{this.renderRatingPoints()}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            this.renderAvatar()
        );
    }
} 
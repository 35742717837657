import React, { Component } from 'react';
import { RandomWidget } from './main/random/RandomWidget';
export class WelcomeDe extends Component {
    static displayName = WelcomeDe.name;
    componentDidMount() {

    }
    componentDidUpdate(prevProp) {

    }

    render() {
        return (
            <section className="bg-white" style={{ "minHeight": "80vw", 'font-family': 'Barlow,sans-serif' }}>
                <div className="row">
                    <div className="col-2">
                    </div>
                    <div className="col-8">
                        <div>
                            <h3 class="display-5">IHRE FALSTAFF BEWERTUNGEN</h3>
                        </div>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div className="row pt-5 pb-5">
                    <div className="col-2">
                    </div>
                    <div className="col-8">
                        <div class="jumbotron">
                            <p class="lead" style={{ width: '75%' }}>Falstaff bewertet Weine, Restaurants, Bars, Cafés und vieles mehr - und unsere Leser lieben und vertrauen den Falstaff-Punkten. Nutzen Sie das Potenzial auch für Ihren Betrieb - mit unseren kostenlosen Widgets, digitalen Plaketten und Shareables für Ihr Lokal bzw. Ihre Weine & Spirits.
                            <br></br>
                                Suchen Sie einfach in der obigen Leiste nach Ihrem Betrieb und Sie erhalten eine Übersicht der verschiedenen Formate, können einzelne Bilder herunterladen oder mittels kleinem Code-Snippet dynamisch in Ihre Website einbinden.</p>
                        </div>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div style={{ backgroundColor: '#f5f5f5' }}>
                    <div className="row pb-4 pt-2" >
                        <div class="jumbotron">
                            <div className="row">
                                <div className="col-2">
                                </div>
                                <div className="col-8">
                                    <h3 class="display-5">Shareables für Facebook & Instagram.</h3>
                                    <p class="lead pt-4">Vermarkten Sie Ihre Bewertung bei Ihren Fans und Followern auf Social Media - mit unseren neuen Formaten: Quadratisch (1200 x 1200 px) für Instagram Beiträge und im Querformat (1200 x 620 px) als Bild für ihr Posting auf Facebook.</p>
                                    <div className="row pt-4">
                                        <div className="col-lg-4 col-md-4 col-sm-6 pt-3">
                                            <RandomWidget format={'1200x1200'} fullHeight={true} lang={this.props.lang}></RandomWidget>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 pt-3">
                                            <RandomWidget format={'620x1200'} fullHeight={false} lang={this.props.lang}></RandomWidget>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                </div>
                            </div>
                            <div className="row pt-5">
                                <div className="col-2">
                                </div>
                                <div className="col-2">
                                    <a className="button button-primary downloadButtonWrapper" onClick={event => window.scrollTo({ top: 0, behavior: 'smooth' })} target="_blank">
                                        <span className="downloadButton">Meinen Betrieb suchen</span>
                                    </a>
                                </div>
                                <div className="col-8">
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row pb-4 pt-2">
                        <div class="jumbotron">
                            <div className="row">
                                <div className="col-2">
                                </div>
                                <div className="col-8">
                                    <h3 class="display-5">Dynamische widgets für ihre website</h3>
                                    <p class="lead pt-4">Der SEO-Booster für ihre Website: unsere Bewertungs-Widgets mit allen Infos zu Ihrem Falstaff Rating in drei kompakten Formaten und in hellem oder dunklem Design. Ideal für Ihren Website-Footer, aktualisiert sich als html-Widget automatisch mit neuen Falstaff-Bewertungen. Überzeugen Sie ihre Website-Besucher mit der unabhängigen Falstaff-Bewertung!</p>
                                    <div className="row pt-4">
                                        <div className="col-lg-3 col-md-3 col-sm-3 pt-3">
                                            <RandomWidget format={'150x180'} fullHeight={true} lang={this.props.lang}></RandomWidget>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-4 pt-3"> 
                                            <RandomWidget format={'150x320'} fullHeight={true} lang={this.props.lang}></RandomWidget>
                                        </div>
                                        <div className="col-lg-5 col-md-5 col-sm-5 pt-3">
                                            <RandomWidget format={'150x540'} fullHeight={true} lang={this.props.lang}></RandomWidget>
                                        </div>
                                     
                                      
                                    </div>
                                </div>
                                <div className="col-2">
                                </div>
                            </div>
                            <div className="row pt-5">
                                <div className="col-2">
                                </div>
                                <div className="col-2">
                                    <a className="button button-primary downloadButtonWrapper" onClick={event => window.scrollTo({ top: 0, behavior: 'smooth' })} target="_blank">
                                        <span className="downloadButton">Meinen Betrieb suchen</span>
                                    </a>
                                </div>
                                <div className="col-8">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pt-4">
                    <div className="col-2">
                    </div>
                    <div className="col-8">
                        <div>
                            <h3 class="display-5">NEU: widgets auch für weine & spirituosen</h3>
                        </div>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div className="row pt-5 pb-5">
                    <div className="col-2">
                    </div>
                    <div className="col-8">
                        <div class="jumbotron">
                            <p class="lead" style={{ width: '75%' }}>Sie kennen die Falstaff-Plaketten vermutlich von Weinflaschen im Einzelhandel - nun gibt es die Falstaff-Punkte für Ihre Produkte auch digital! In verschiedenen Formaten, hellem oder dunklem Design und gegebenenfalls auch mit ihrem Flaschenfoto - kostenlos zum Download! </p>
                        </div>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div style={{ backgroundColor: '#f5f5f5' }}>
                    <div className="row pb-4 pt-2" >
                        <div class="jumbotron">
                            <div className="row">
                                <div className="col-2">
                                </div>
                                <div className="col-8">
                                    <h3 class="display-5">Social media boost für ihre Produkte</h3>
                                    <p class="lead pt-4">Ihr Wein und/oder Spirit wurde von Falstaff gut bewertet? Lassen Sie es Ihre Follower wissen und laden Sie einfach und unkompliziert die Facebook & Instagram Shareables für Ihre Produkte herunter - völlig kostenlos.</p>
                                    <div className="row pt-4">
                                        <div className="col-lg-4 col-md-4 col-sm-6 pt-3">
                                            <RandomWidget format={'1200x1200'} fullHeight={true} lang={this.props.lang} type={'product'}></RandomWidget>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 pt-3">
                                            <RandomWidget format={'620x1200'} fullHeight={false} lang={this.props.lang} type={'product'}></RandomWidget>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                </div>
                            </div>
                            <div className="row pt-5">
                                <div className="col-2">
                                </div>
                                <div className="col-2">
                                    <a className="button button-primary downloadButtonWrapper" onClick={event => window.scrollTo({ top: 0, behavior: 'smooth' })} target="_blank">
                                        <span className="downloadButton">Meinen Betrieb suchen</span>
                                    </a>
                                </div>
                                <div className="col-8">
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row pb-4 pt-2">
                        <div class="jumbotron">
                            <div className="row">
                                <div className="col-2">
                                </div>
                                <div className="col-8">
                                    <h3 class="display-5">digitale plaketten für ihren shop</h3>
                                    <p class="lead pt-4">Nutzen Sie die html und/oder Bild-Plaketten für ihre Wein- und Spirituosenbewertungen, um die Falstaff-Bewertung auf Ihrer Website bzw. in ihrem Shop hervorzuheben. Gute Ratings können den Kaufentscheid Ihrer Kunden wesentlich beeinflussen!</p>
                                    <div className="row pt-4">
                                        <div className="col-lg-3 col-md-3 col-sm-3 pt-3">
                                            <RandomWidget format={'150x150'} fullHeight={true} lang={this.props.lang} type={'product'}></RandomWidget>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-4 pt-3">
                                            <RandomWidget format={'150x320'} fullHeight={true} lang={this.props.lang} type={'product'}></RandomWidget>
                                        </div>
                                        <div className="col-lg-5 col-md-5 col-sm-5 pt-3">
                                            <RandomWidget format={'150x540'} fullHeight={true} lang={this.props.lang} type={'product'}></RandomWidget>
                                        </div>
                                     
                                     
                                    </div>
                                </div>
                                <div className="col-2">
                                </div>
                            </div>
                            <div className="row pt-5">
                                <div className="col-2">
                                </div>
                                <div className="col-2">
                                    <a className="button button-primary downloadButtonWrapper" onClick={event => window.scrollTo({ top: 0, behavior: 'smooth' })} target="_blank">
                                        <span className="downloadButton">Meinen Betrieb suchen</span>
                                    </a>
                                </div>
                                <div className="col-8">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pt-4">
                    <div className="col-2">
                    </div>
                    <div className="col-8">
                        <div>
                            <h3 class="display-5">Fragen, wünsche oder anmerkungen?</h3>
                        </div>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div className="row pt-5 pb-5">
                    <div className="col-2">
                    </div>
                    <div className="col-8">
                        <div class="jumbotron">
                            <p class="lead" style={{ width: '75%' }}>Sie haben Probleme und/oder Feedback zu unserem Promotion-Cockpit? Wir helfen gerne weiter. Bitte kontaktieren Sie uns via guides@falstaff.com, idealerweise mit Link und Screenshot.</p>
                        </div>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div className="row pt-5 pb-5">
                </div>


            </section>

        );
    }
}
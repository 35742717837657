import React, { Component } from 'react';
import './autocomplete.css';
import { Spinner } from '../../main/utils/Spinner'
import { storeProvider } from '../../../store/storeProvider';
export class Autocomplete extends Component {


    static currentStoreSubscription;
    static currentStore;
    constructor() {
        super();
        this.currentStore = storeProvider.getStore();
        this.state = {
            searchText: '',
            finalizedText: '',
            isLoading: false,
            results: [],
            closeAutoComplete: false,
        };
        this.typingTimer = null;
        this.doneTypingInterval = 250;
        this.setAsyncTimeout = (cb, timeout = 0) => {
            let id;
            const promise = new Promise(resolve => {
                id = setTimeout(() => { cb(); resolve(); }, timeout);
            });
            return {
                id, promise
            };
        }
    }


    fetchData(val) {
        let lang = this.currentStore.getState().language;
        fetch('/api/search?value=' + val + '&lang=' + lang)
            .then(response => response.json())
            .then(data => this.setState({ isLoading: false, results: data }));
    }




    async setText(val) {
        this.setState({
            searchText: val,
            finalizedText: '',
            closeAutoComplete: false,
        });

        clearTimeout(this.typingTimer);
        const { id, promise } = await this.setAsyncTimeout(() => {
            this.setState({
                results: [],
                finalizedText: this.state.searchText,
                isLoading: true
            })
            this.fetchData(this.state.searchText);
        }, this.doneTypingInterval);

        this.typingTimer = id;
    }

    closeAutoComplete() {
        this.setState({ closeAutoComplete: true });
    }

    renderAccountHeader() {
        let lang = this.currentStore.getState().language;
        if (lang === 'de')
            return (
                <h4 style={{ 'font-family': 'Barlow,sans-serif' }}>Betriebe & Produzenten</h4>
            )
        else
            return (
                <h4 style={{ 'font-family': 'Barlow,sans-serif' }}>Accounts & Producers</h4>
            )
    }

    applyCurrentItem(e, item, isProduct) {
        item.isProduct = isProduct;
        this.props.callback(item);
    }

    renderAutocompleteItems(isProduct) {
        return (

            this.state.results.filter(g => g.isProduct === isProduct).map((item, i) => {
                return (
                    <div className="row autocomplete-items-div" onClick={event => this.applyCurrentItem(event, item, isProduct)}>
                        <div className="col-12" style={{ 'font-family': 'Barlow,sans-serif' }}>
                            {item.name}
                            <br />
                            <small>{item.subInformation}</small>
                        </div>
                    </div>
                )
            })

        );
    }


    renderProductHeader() {
        let lang = this.currentStore.getState().language;
        if (lang === 'de')
            return (
                <h4>Produkte</h4>
            )
        else
            return (
                <h4>Products</h4>
            )
    }

    renderNothingFound() {
        let lang = this.currentStore.getState().language;
        let text = lang === 'de' ? "Keine Ergebnisse" : "No results";
        return (
            <div className="pt-5 text-center">
                <h4>
                    {text}
                </h4>
            </div>
        );
    }

    renderMainContent() {
        let isLoading = (this.state.finalizedText === '' && this.state.searchText.length > 0) || this.state.isLoading === true;
        let hasResults = this.state.results !== [] && this.state.results.length > 0;
        if (isLoading)
            return (
                <Spinner></Spinner>
            );
        if (hasResults)
            return (
                <div style={{ "padding-left": "10px", "padding-right": "10px" }}>
                    {
                        this.state.results.filter(g => g.isProduct === false).length > 0 ?
                            <div className="pt-3">
                                {
                                    this.renderAccountHeader()
                                }
                                <hr />
                                {
                                    this.renderAutocompleteItems(false)
                                }
                            </div> : <div></div>
                    }
                    {
                        this.state.results.filter(g => g.isProduct === true).length > 0 ?
                            <div className="pt-3">
                                {
                                    this.renderProductHeader()
                                }
                                <hr />
                                {
                                    this.renderAutocompleteItems(true)
                                }

                            </div> : <div></div>
                    }
                </div>
            );
        else
            return (
                this.renderNothingFound()
            );

    }



    render() {
        if (this.state.searchText === '')
            return (<div></div>);
        if (this.state.closeAutoComplete === true)
            return (<div></div>);
        return (
            <div className="autocomplete">
                <div className="row">
                    <div className="col-12">
                        <div id="autocomplete-list" className="autocomplete-items">
                            {
                                this.renderMainContent()
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }


}

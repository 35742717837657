import React, { Component } from 'react';
import classnames from 'classnames';
import { Alert } from 'reactstrap'
import './widgetheader.css'
import headerimage from './image.svg';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { DownloadModal } from '../download/DownloadModal';
import { WidgetTeaser } from '../teaser/WidgetTeaser'
import { storeProvider } from '../../../store/storeProvider';
export class WidgetHeader extends Component {

    constructor() {
        super();
        this.state = {
            data: {
                accountName: '',
                accountNameEn: '',
                products: []
            },
            accountId: '',
        };
        this.renderTeaserDe = this.renderTeaserDe.bind(this);
        this.renderTeaserEn = this.renderTeaserEn.bind(this);
    }


    componentDidMount() {
        this.setState({ data: this.props.data, accountId: this.props.accountId });
    }


    renderTeaserDe() {
        if (this.state.data.products === null || this.state.data.products.length === 0)
            return;
        return (
            <WidgetTeaser headLine={"Digitale plaketten für ihre produkte"} subText={"Jetzt NEU: Entdecken Sie Widgets, Plaketten und Social Media Shareables für ihre Produkte und nutzen Sie diese in der Vermarktung - kostenlos! Zudem können Sie über unsere myproducts-Site weitere Flaschenfotos hochladen."} uploadLink={"https://myproducts.falstaff.com/?id=" + this.state.accountId} linkLabel={"Flaschenbilder hochladen"}>
            </WidgetTeaser>
        );
    }

    renderTeaserEn() {
        if (this.state.data.products === null || this.state.data.products.length === 0)
            return;
        return (
            <WidgetTeaser headLine={"Digital badges for your products"} subText={"NEW: Discover widgets, badges and social media shareables for your products and use them in your marketing - free of charge! You can also upload additional bottle photos via our myproducts site."} uploadLink={"https://myproducts.falstaff.com/?id=" + this.state.accountId} linkLabel={"Upload product images"}>
            </WidgetTeaser>
        );
    }


    render() {
        return (
            <div className="pb-3">

                {
                    (this.state.data.products === null || this.state.data.products.length === 0) ?
                        (
                            <div className="row">
                                <div className="col-12">
                                    <h2>{this.state.data.accountName}</h2>
                                </div>
                                {/*<div className="col-6 justify-content-end" style={{ display: 'flex' }}>*/}
                                {/*    <a onClick={event => this.downloadModal.showModal(this.state.data.accountName, this.state.accountId)} className="button button--primary button--small" style={{ alignSelf: 'center', height: '80%' }} target="_blank">*/}
                                {/*        <span className="">Alle herunterladen</span>*/}
                                {/*    </a>*/}
                                {/*</div>*/}
                            </div>
                        )
                        :
                        (
                            <div className="row">
                                <div className="col-12">
                                    <h2>{this.state.data.accountName}</h2>
                                </div >
                            </div >
                        )
                }

                {
                    storeProvider.getStore().getState().language === 'en' ? this.renderTeaserEn() : this.renderTeaserDe()

                }
                <DownloadModal ref={downloadModal => this.downloadModal = downloadModal}></DownloadModal>
            </div>
        );
    }
} 
import React, { Component } from 'react';
import classnames from 'classnames';
import { Alert } from 'reactstrap'
import './teaser.css'
import headerimage from './image.svg';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { DownloadModal } from '../download/DownloadModal';
import { storeProvider } from '../../../store/storeProvider';
export class WidgetTeaser extends Component {

    render() {
        return (
            <div className="row pt-4">
                <div className="col-12">
                    <div class="card">
                        <div class="card-body headerContent p-4">
                            <h4 className="h4 pb-4">{this.props.headLine}</h4>
                            <p class="card-text pb-4">{this.props.subText}</p>
                            <a className="button button--secondary downloadButton" rel="noreferrer" target="_blank" href={this.props.uploadLink}>
                                <span>{this.props.linkLabel}</span>
                            </a>
                        </div>
                    </div>
                </div></div>
        )
    }
    //render() {
    //    return (
    //        <div className="row pt-4">
    //            <div className="col-12" style={{ height: 300 }} >
    //                <section className="section section--teaser-promotion bg-gray">
    //                    <div className="teaser" style={{ display: "flex !important" }}>
    //                        <div className="headerContent">
    //                            <div className="row">
    //                                <div className="col-12 col-left headerText">
    //                                    <div className="teaser__title">
    //                                        <h4 className="h4">{this.props.headLine}</h4>
    //                                    </div>
    //                                    <div className="teaser__text content-area">
    //                                        <p>{this.props.subText}</p>
    //                                    </div>
    //                                    <div className="teaser__buttons">

    //                                        {/*<a onClick={event => this.downloadModal.showModal(this.state.data.accountName, this.state.accountId)} className="button button--primary downloadWrapper" target="_blank">*/}
    //                                        {/*    <span className="downloadButton2">Download all</span>*/}
    //                                        {/*</a>*/}

    //                                    </div>
    //                                </div>
    //                            </div>
    //                        </div>
    //                    </div>
    //                </section>
    //            </div></div>
    //    );
    //}


} 
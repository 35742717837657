import React, { Component } from 'react';
import { Footer } from './Footer';


export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div>
                <div>
                    {this.props.children}
                </div>
                <Footer>
                </Footer>
            </div>
        );
    }
}


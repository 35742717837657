import { stateReducer } from "./statereducer";
import { createStore } from 'redux'

export class storeProvider {
    static store;
    static getStore() {
        if (this.store !== undefined)
            return this.store;
        this.store = createStore(stateReducer);
        return this.store;
    }
}       

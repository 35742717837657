import React, { Component } from 'react';
import './widgetitem.css'
import { WidgetModal } from './modal/WidgetModal';
export class WidgetItem extends Component {
    constructor() {
        super();
        this.state = {
            data: {
                widgets: []
            },
            accountName: ''
        };
        this.renderAccountWidgetCategory = this.renderAccountWidgetCategory.bind(this);
    }


    componentDidMount() {
        this.setState({ data: this.props.data, accountName: this.props.accountName });
    }

    renderAccountWidgetCategory() {
        let reduced = this.state.data.widgets.reduce(function (r, a) {
            r[a.widgetCategoryLabel] = r[a.widgetCategoryLabel] || [];
            r[a.widgetCategoryLabel].push(a);
            return r;
        }, Object.create(null));
        console.log(reduced)
        return (
            <div>
                {
                    <div>
                        {
                            Object.keys(reduced).map((t, i) => {
                                return (
                                    <div className="pb-5">
                                        <div className="row">
                                            <div className="col-12">
                                                <hr />
                                                <h4 className="pt-3">{t}</h4>
                                            </div>
                                        </div>
                                        <div className="row pt-3">
                                            {
                                                reduced[t].map((item, i) => {
                                                    return (
                                                        <div className="col-md-auto pt-2">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h5>
                                                                        {item.widgetFormatLabel} {item.isResized ? "(Abb. verkleinert)" : ""}
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <div className="row pt-2">
                                                                <div className="col">
                                                                    {
                                                                        item.originalWidth > 500 && this.props.currentWidth <= 500 ?
                                                                            <iframe scrolling="no" width={this.props.currentWidth - 25} height={(this.props.currentWidth - 25) / item.previewRatio} src={item.previewUrl.concat('&width=', (this.props.currentWidth - 25).toString())} ></iframe>
                                                                            :
                                                                            <iframe scrolling="no" width={item.previewWidth} height={item.previewHeight} src={item.previewUrl} ></iframe>
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div className="row pt-2">
                                                                <div className="col">
                                                                    <a className="button button-primary downloadButtonWrapper" onClick={event => this.widgetModal.showModal(this.state.data.widgets, this.state.accountName, item)} target="_blank">
                                                                        <span className="downloadButton">Teilen</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>

                                )
                            })
                        }
                    </div>
                }
                <WidgetModal currentWidth={this.props.currentWidth} ref={widgetModal => this.widgetModal = widgetModal}></WidgetModal>
            </div>

        )

    }
    renderAccountWidgetItems(widget) {

    }

    render() {
        return (
            this.renderAccountWidgetCategory()
        );
    }
} 
import React, { Component } from 'react';
import './spinner.css'
export class Spinner extends Component {
    constructor() {
        super();
        this.state = {
            display: true
        };
    }

    render() {
        return (
            <div class="row">
                <div class="col-4">
                </div>
                <div class="col-4 center text-center">
                    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                </div>
                <div class="col-4">
                </div>
            </div>

        );
    }
}
import React, { Component } from 'react';
export class NotFound extends Component {
    constructor() {
        super();
        this.state = {
            display: true
        };
    }

    render() {
        return (
            <div class="row" style={{ minHeight:"80vw" }}>
                <div class="col-4">
                </div>
                <div class="col-4 center text-center">
                    404
                    <hr/>
                    Page not found
                </div>
                <div class="col-4">
                </div>
            </div>

        );
    }
}
import React, { Component } from 'react';
import { Container, Row, Col, Card, CardImg, Badge, Button, Input, CardTitle, CardText, CardFooter, FormGroup, Label } from 'reactstrap';
import logo from '../../resources/scss/static/assets/logo.svg';
import './header.css';
import { Autocomplete } from './autocomplete/autocomplete'
import { storeProvider } from '../../store/storeProvider';
import { useParams, useNavigate } from "react-router-dom";
import utils from '../../utils/utils'
import { BsSearch } from "react-icons/bs";

export class Header extends Component {
    static displayName = Header.name;
    static currentStoreSubscription;
    static currentStore;
    constructor() {
        super();
        this.currentStore = storeProvider.getStore();
        this.state = {
            searchText: '',
            selectedId: '',
            language: 'de',
            global: this.currentStore.getState(),
        };
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
    }




    componentWillUnmount() {

    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedAccountName === '')
            return;
        this.setState({
            searchText: nextProps.selectedAccountName
        })
    }


    componentDidMount() {
        this.setState({
            searchText: this.props.selectedAccountName ?? ''
        })
    }




    handleTextChange(value) {
        this.autocomplete.setText(value);
        this.setState({
            searchText: value
        });
        this.currentStore.dispatch({
            type: 'SET_SEARCH_TEXT',
            text: value
        })
    }

    handleLanguageChange(value) {
        let state = this.currentStore.getState();
        this.currentStore.dispatch({
            type: 'SET_LANGUAGE',
            lang: value,
        });
    }


    onAutoCompleteTextSet(val) {
        this.currentStore.dispatch({
            type: 'REMOVE_RATING_PARAM'
        });

        let params = this.currentStore.getState().params;
        utils.pushUrl(params.currentPortalLang, params.currentSlugPrefix, params.currentSlug, undefined);

        this.currentStore.dispatch({
            type: 'SET_AUTOCOMPLETE_RESULT',
            accountId: val.id,
            productId: val.productId,
            name: val.name,
            ratingId: undefined,
            isProductReference: val.isProduct,
            slug: val.slug,
            completed: Date.now
        });
        this.setState({
            searchText: val.name
        });
        this.autocomplete.closeAutoComplete();
    }

    render() {
        console.log(this.props.currentWidth + ' from header');
        const isMobile = this.props.currentWidth <= 500;
        return (
            <div className="bg-dark">
                <Row className="align-items-center" style={{
                    margin: 0,
                    padding: 0
                }}>
                    <Col className="bg-dark" lg="1" md="1" sm="0">
                    </Col>
                    <Col className="bg-dark maxZindex" lg="2" md="2" sm="2" xs="1">
                        <FormGroup className={isMobile ? "maxZindex my-2 pt-5" : "maxZindex my-2 pt-4"} >
                            <select
                                className="maxZindex"
                                value={this.props.language === 'de' ? 'de' : 'en'}
                                style={{
                                    color: "white",
                                    background: "#212529",
                                    border: isMobile ? 'none' : " 1px solid white",
                                    outline: "0px",
                                    'font-family': 'Barlow,sans-serif',
                                    padding: isMobile ? 'unset' : '0.375rem 0.375rem 0.375rem 0.375rem',
                                    paddingRight: isMobile ? 'unset' : '2.0rem',
                                    appearance: isMobile ? 'auto' : 'none'
                                }}
                                onChange={event => this.handleLanguageChange(event.target.value)}
                            >
                                <option value="de">
                                    <span style={{ 'font-family': 'Barlow,sans-serif' }}> {this.props.currentWidth <= 850 ? "DE" : "Deutsch"}</span>
                                </option>
                                <option value="en">
                                    <span style={{ 'font-family': 'Barlow,sans-serif' }}> {this.props.currentWidth <= 850 ? "EN" : "English"}</span>
                                </option>
                            </select>
                        </FormGroup>
                    </Col>
                    <Col className="bg-dark" lg="6" md="6" sm="8" xs="10">
                        <Card className="my-2 pt-4 bg-dark no-border" style={{ border: 'none' }}>
                            <a href="/">
                                {
                                    isMobile ? <CardImg
                                        className="bg-dark pt-3"
                                        alt="Card image cap"
                                        src={logo}
                                        style={{
                                            height: 60,
                                        }}
                                        top
                                        width="100%"
                                    >
                                    </CardImg> : <CardImg
                                        className="bg-dark pt-3"
                                        alt="Card image cap"
                                        src={logo}
                                        style={{
                                            height: 72,
                                        }}
                                        top
                                        width="100%"
                                    >
                                    </CardImg>
                                }

                            </a>
                        </Card>
                    </Col>
                    <Col className="bg-dark" lg="3" md="3" sm="2" xs="1">
                    </Col>
                </Row>
                <Container fluid="lg">

                    <Row className="pb-4">
                        <Col className="bg-dark">
                        </Col>
                        <Col className="bg-dark mt-negative" lg="10" sm="10" md="10">

                            {
                                isMobile ? <CardTitle className="pt-5" style={{ height: 160 }}>
                                    <h2 style={{
                                    
                                        textAlign: 'center',
                                        fontSize: 'larger',
                                        color: 'white'
                                    }}><span>Promotion-Cockpit</span></h2>
                                </CardTitle> :
                                    <CardTitle className="text-center" style={{ height: 100 }} >
                                        <h2 className="text-divider"><span>Promotion-Cockpit</span></h2>
                                    </CardTitle>
                            }

                            <CardText className="text-center mt-negative">
                                <div className="button-grouped">
                                    <Input style={{ 'font-family': 'Barlow,sans-serif' }} value={this.state.searchText} onPaste={event => this.handleTextChange(event.target.value)} onChange={event => this.handleTextChange(event.target.value)} className="input-rounded" placeholder={
                                        isMobile ?
                                            "Detailseiten-Adresse oder Name" :
                                            "Geben Sie Adresse/URL einer Falstaff-Detailseite ein - oder suchen Sie nach einem Namen"
                                    }>
                                    </Input>
                                    {
                                        isMobile ? <Button className="badge-button bg-light " active outline
                                            color="light">
                                            <BsSearch />
                                        </Button>
                                            :
                                            <Button className="badge-button bg-light d-none d-sm-block" active outline
                                                color="light">
                                                <Badge
                                                    className="text-light"
                                                    color="secondary"
                                                    style={{ 'font-family': 'Barlow,sans-serif' }}
                                                    pill>
                                                    Widgets & Urkunden abrufen
                                                </Badge>
                                            </Button>
                                    }


                                </div>

                            </CardText>
                            <Autocomplete ref={autocomplete => this.autocomplete = autocomplete} callback={this.onAutoCompleteTextSet.bind(this)}></Autocomplete>
                        </Col>
                        <Col className="bg-dark">
                        </Col>
                    </Row>
                </Container>
            </div>


        );
    }
}
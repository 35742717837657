import React, { Component } from 'react';
import { RandomWidget } from './main/random/RandomWidget';

export class WelcomeEn extends Component {
    static displayName = WelcomeEn.name;
    componentDidMount() {

    }
    componentDidUpdate(prevProp) {

    }

    render() {
        return (
            <section className="bg-white" style={{ "minHeight": "80vw", 'font-family': 'Barlow,sans-serif' }}>
                <div className="row">
                    <div className="col-2">
                    </div>
                    <div className="col-8">
                        <div>
                            <h3 class="display-5">your falstaff-ratings</h3>
                        </div>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div className="row pt-5 pb-5">
                    <div className="col-2">
                    </div>
                    <div className="col-8">
                        <div class="jumbotron">
                            <p class="lead" style={{ width: '75%' }}>Falstaff rates wines, restaurants, bars, cafés and much more - and our readers love and trust the Falstaff points. Utilise the potential for your business too - with our free widgets, digital badges and shareables for your restaurant or your wines & spirits.
                            <br></br>
                                Simply search for the name of your establishment in the search bar above and you will receive an overview of the various formats, can download individual images or integrate them dynamically into your website using a small code snippet.</p>
                        </div>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div style={{ backgroundColor: '#f5f5f5' }}>
                    <div className="row pb-4 pt-2" >
                        <div class="jumbotron">
                            <div className="row">
                                <div className="col-2">
                                </div>
                                <div className="col-8">
                                    <h3 class="display-5">shareables FOR Facebook & Instagram</h3>
                                    <p class="lead pt-4">Market your rating to your fans and followers on social media - with our new formats: Square (1200 x 1200 px) for Instagram posts and in landscape format (1200 x 620 px) as an image for your post on Facebook.</p>
                                    <div className="row pt-4">
                                        <div className="col-lg-4 col-md-4 col-sm-6 pt-3">
                                            <RandomWidget format={'1200x1200'} fullHeight={true} lang={this.props.lang}></RandomWidget>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 pt-3"> 
                                            <RandomWidget format={'620x1200'} fullHeight={false} lang={this.props.lang}></RandomWidget>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                </div>
                            </div>
                            <div className="row pt-5">
                                <div className="col-2">
                                </div>
                                <div className="col-2">
                                    <a className="button button-primary downloadButtonWrapper" onClick={event => window.scrollTo({ top: 0, behavior: 'smooth' })} target="_blank">
                                        <span className="downloadButton">Search my account</span>
                                    </a>
                                </div>
                                <div className="col-8">
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row pb-4 pt-2">
                        <div class="jumbotron">
                            <div className="row">
                                <div className="col-2">
                                </div>
                                <div className="col-8">
                                    <h3 class="display-5">dynamic widgets for your website</h3>
                                    <p class="lead pt-4">The SEO booster for your website: our rating widgets with all the information about your Falstaff rating in three compact formats and in a light or dark design. Ideal for your website footer, the html widget updates automatically with new Falstaff ratings. Convince your website visitors with the independent Falstaff rating!</p>
                                    <div className="row pt-4">
                                        <div className="col-lg-3 col-md-3 col-sm-3 pt-3">
                                            <RandomWidget format={'150x180'} fullHeight={true} lang={this.props.lang}></RandomWidget>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-4 pt-3">
                                            <RandomWidget format={'150x320'} fullHeight={true} lang={this.props.lang}></RandomWidget>
                                        </div>
                                        <div className="col-lg-5 col-md-5 col-sm-5 pt-3">
                                            <RandomWidget format={'150x540'} fullHeight={true} lang={this.props.lang}></RandomWidget>
                                        </div>
                                     
                                      
                                    </div>
                                </div>
                                <div className="col-2">
                                </div>
                            </div>
                            <div className="row pt-5">
                                <div className="col-2">
                                </div>
                                <div className="col-2">
                                    <a className="button button-primary downloadButtonWrapper" onClick={event => window.scrollTo({ top: 0, behavior: 'smooth' })} target="_blank">
                                        <span className="downloadButton">Search my account</span>
                                    </a>
                                </div>
                                <div className="col-8">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pt-4">
                    <div className="col-2">
                    </div>
                    <div className="col-8">
                        <div>
                            <h3 class="display-5">NEW: widgets also for wines & spirits</h3>
                        </div>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div className="row pt-5 pb-5">
                    <div className="col-2">
                    </div>
                    <div className="col-8">
                        <div class="jumbotron">
                            <p class="lead" style={{ width: '75%' }}>You probably know the Falstaff badges from wine bottles in retail stores - now the Falstaff points for your products are also available digitally! In various formats, in a light or dark design and, if required, with your bottle photo - free to download!</p>
                        </div>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div style={{ backgroundColor: '#f5f5f5' }}>
                    <div className="row pb-4 pt-2" >
                        <div class="jumbotron">
                            <div className="row">
                                <div className="col-2">
                                </div>
                                <div className="col-8">
                                    <h3 class="display-5">Social media boost for your products</h3>
                                    <p class="lead pt-4">Has your wine and/or spirit been rated highly by Falstaff? Let your followers know and simply download the Facebook & Instagram shareables for your products - completely free of charge.</p>
                                    <div className="row pt-4">
                                        <div className="col-lg-4 col-md-4 col-sm-6 pt-3">
                                            <RandomWidget format={'1200x1200'} fullHeight={true} lang={this.props.lang} type={'product'}></RandomWidget>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 pt-3">
                                            <RandomWidget format={'620x1200'} fullHeight={false} lang={this.props.lang} type={'product'}></RandomWidget>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                </div>
                            </div>
                            <div className="row pt-5">
                                <div className="col-2">
                                </div>
                                <div className="col-2">
                                    <a className="button button-primary downloadButtonWrapper" onClick={event => window.scrollTo({ top: 0, behavior: 'smooth' })} target="_blank">
                                        <span className="downloadButton">Search my account</span>
                                    </a>
                                </div>
                                <div className="col-8">
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row pb-4 pt-2">
                        <div class="jumbotron">
                            <div className="row">
                                <div className="col-2">
                                </div>
                                <div className="col-8">
                                    <h3 class="display-5">digital badges for your shop</h3>
                                    <p class="lead pt-4">Use the html and/or image badges for your wine and spirits ratings to emphasise the Falstaff rating on your website or in your shop. Good ratings can have a significant influence on your customers' purchasing decisions!</p>
                                    <div className="row pt-4">
                                        <div className="col-lg-3 col-md-3 col-sm-3 pt-3">
                                            <RandomWidget format={'150x150'} fullHeight={true} lang={this.props.lang} type={'product'}></RandomWidget>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-4 pt-3">
                                            <RandomWidget format={'150x320'} fullHeight={true} lang={this.props.lang} type={'product'}></RandomWidget>
                                        </div>
                                        <div className="col-lg-5 col-md-5 col-sm-5 pt-3">
                                            <RandomWidget format={'150x540'} fullHeight={true} lang={this.props.lang} type={'product'}></RandomWidget>
                                        </div>
                                     
                                     
                                    </div>
                                </div>
                                <div className="col-2">
                                </div>
                            </div>
                            <div className="row pt-5">
                                <div className="col-2">
                                </div>
                                <div className="col-2">
                                    <a className="button button-primary downloadButtonWrapper" onClick={event => window.scrollTo({ top: 0, behavior: 'smooth' })} target="_blank">
                                        <span className="downloadButton">Search my account</span>
                                    </a>
                                </div>
                                <div className="col-8">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pt-4">
                    <div className="col-2">
                    </div>
                    <div className="col-8">
                        <div>
                            <h3 class="display-5">Questions or comments?</h3>
                        </div>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div className="row pt-5 pb-5">
                    <div className="col-2">
                    </div>
                    <div className="col-8">
                        <div class="jumbotron">
                            <p class="lead" style={{ width: '75%' }}>Do you have problems and/or feedback on our promotion cockpit? We are happy to help. Please contact us via guides@falstaff.com, ideally with a link and screenshot.</p>
                        </div>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div className="row pt-5 pb-5">
                </div>


            </section>

        );
    }
}
import React, { Component } from 'react';
export class Footer extends Component {
    static displayName = Footer.name;
    static currentLanguage = '';
    constructor() {
        super();
        this.state = {

        };
    }
    componentDidMount() {
    }
    componentDidUpdate(prevProp) {

    }
    render() {
        return (
            <footer id="footer" class="footer" style={{
                "bottom": "0",
                "width": "100%",
                "height": "60px",
                "line- height": "60px",
            }}>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="footer-col-top">
                            <div className="row">
                                <div className="col-12 col-md-6 col-left">
                                    <div className="logo">
                                        <a href="/at">
                                            <img loading="lazy" width="239" height="71" src="https://www.falstaff.com/static/assets/logo-white.svg" alt="Falstaff Logo" />
                                        </a>
                                    </div>
                                    <div className="address">
                                        <p>Schottenring 2-6, A-1010 Wien<br /><a href="tel:+4319042141">+43 1 90 42 141</a>, <a href="mailto:redaktion@falstaff.at">redaktion@falstaff.at</a></p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-right">
                                    <a href="https://www.facebook.com/Falstaff.Magazin" className="social-link" target="blank">
                                        <span className="icon-facebook"></span>
                                    </a>
                                    <a href="https://twitter.com/falstaff" className="social-link" target="blank">
                                        <span className="icon-twitter"></span>
                                    </a>
                                    <a href="https://www.instagram.com/falstaff.magazin/" className="social-link" target="blank">
                                        <span className="icon-instagram"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="footer-col-bottom">
                            <div className="row">
                                <div className="col-12 col-md-6 col-left">
                                    <div className="secondary-navigation">
                                        <div className="secondary-navigation">
                                            <a href="https://www.falstaff.com/at/ueber-uns" className="nav-link">Über uns</a>
                                            <a href="https://www.falstaff.com/at/newsletter-at" className="nav-link">Newsletter</a>
                                            <a href="https://www.falstaff.com/at/b2b-mediadaten-at" className="nav-link">B2B Mediadaten</a>
                                            <a href="https://www.falstaff.com/at/partner" className="nav-link">Partner</a>
                                            <a href="https://www.falstaff.com/at/autoren" className="nav-link">Autoren</a>
                                            <a href="https://www.falstaff.com/at/falstaff-apps" className="nav-link">Apps</a>
                                            <a href="https://www.falstaff.com/at/presse" className="nav-link">Presse</a>
                                            <a href="https://www.falstaff.com/at/jobs" className="nav-link">Jobs</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-right">
                                    <div className="secondary-navigation">
                                        <a href="https://www.falstaff.com/at/impressum-at" className="nav-link">Impressum</a>
                                        <a href="https://www.falstaff.com/at/agb-at" className="nav-link">AGB</a>
                                        <a href="https://www.falstaff.com/at/nutzungsbedingungen-at" className="nav-link">Nutzungsbedingungen</a>
                                        <a href="https://www.falstaff.com/at/datenschutz-at" className="nav-link">Datenschutzerklärung</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        );
    }
}
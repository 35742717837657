import React, { Component, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label } from 'reactstrap';
import logo from '../../../../resources/scss/static/assets/logo.svg';
import { Spinner } from '../../utils/Spinner'
import './widgetmodal.css'
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export class WidgetModal extends Component {
    constructor() {
        super();
        this.state = {
            widgets: [],
            displayModal: false,
            accountName: '',
            selectedOption: '',
            currentWidget: {},
            isLoading: false
        };
        this.toggleModal = this.toggleModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.selectOption = this.selectOption.bind(this);
        this.saveFile = this.saveFile.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.sendToEmail = this.sendToEmail.bind(this);
    }

    showModal(widgets, accountName, item) {
        this.setState({
            widgets: widgets,
            displayModal: true,
            accountName: accountName,
            selectedOption: '',
            currentWidget: item,
            isLoading: false,
        });
    }

    toggleModal() {
        let display = this.state.displayModal;
        this.setState({
            displayModal: !display
        });
        if (!display === false && this.props.callback !== undefined) {
            this.props.callback();
        }
    }

    selectOption(opt) {
        this.setState({
            selectedOption: opt
        });
    }

    getCurrentIndex() {
        return this.state.widgets.indexOf(this.state.currentWidget);
    }
    canShowSwipePrev() {
        return this.getCurrentIndex() > 0;
    }
    canShowSwipeNext() {
        return this.getCurrentIndex() < this.state.widgets.length - 1;
    }

    async saveFile() {
        let resp = await fetch(this.state.currentWidget.fileDownloadUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'image/png',
            },
        });
        let blob = await resp.blob();
        // Create blob link to download
        const url = window.URL.createObjectURL(
            new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            this.state.currentWidget.fileName + '.png',
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        toast.success('Download erfolgreich!', { containerId: 'HEADER' });
    }

    sendToEmail() {
        let text = '';
        if (this.state.selectedOption === 'html') {
            text = this.state.currentWidget.htmlPreTag;
        }

        else if (this.state.selectedOption === 'link') {
            text = this.state.currentWidget.cdnUrl;
        }


        const link = document.createElement('a');
        link.href = 'mailto:?subject=&body=' + text;
        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    renderFooter() {
        if (this.state.selectedOption !== '')
            return (
                <ModalFooter style={{ 'font-family': 'Barlow,sans-serif' }}>
                    {
                        (this.state.selectedOption !== 'image') ?
                            <Button className="button button-primary widgetButton" onClick={this.sendToEmail}>
                                An techn. Dienstleister mailen
                            </Button> : ''
                    }
                    {
                        (this.state.selectedOption !== 'image') ?
                            <Button className="button button-primary widgetButtonInverse" onClick={this.copyToClipboard}>
                                Kopieren
                            </Button> : ''
                    }
                    {
                        (this.state.selectedOption === 'image') ?
                            <Button className="button button-primary widgetButtonInverse" onClick={this.saveFile}>
                                Speichern
                            </Button> : ''
                    }

                </ModalFooter>
            )
    }

    copyToClipboard() {
        if (this.state.selectedOption === 'html') {
            navigator.clipboard.writeText(this.state.currentWidget.htmlPreTag);
            toast.success('HTML erfolgreich kopiert!', { containerId: 'HEADER' });
        }

        else if (this.state.selectedOption === 'link') {
            navigator.clipboard.writeText(this.state.currentWidget.cdnUrl);
            toast.success('Link erfolgreich kopiert!', { containerId: 'HEADER' });
        }

    }
    renderSelectedOption() {
        if (this.state.selectedOption === 'html')
            return (
                <div className="col-11 justify-content-center align-self-center pt-4 pb-4 bg-white">
                    <pre style={{ textWrap: "pretty" }}>
                        {this.state.currentWidget.htmlPreTag}
                    </pre>
                </div >
            )
        else if (this.state.selectedOption === 'link')
            return (
                <div className="col-11 justify-content-center align-self-center pt-4 pb-4 bg-white">
                    <pre style={{ textWrap: "pretty" }}>
                        {this.state.currentWidget.mailPreTag}
                    </pre>
                </div>
            )
        else
            return ''
    }

    swipe(prev, next) {
        let currentIndex = this.getCurrentIndex();
        if (prev)
            currentIndex = currentIndex - 1;
        else if (next)
            currentIndex = currentIndex + 1;
        if (currentIndex >= 0 && currentIndex < this.state.widgets.length) {
            this.setState({
                isLoading: true
            });
            setTimeout(() => {
                let widget = this.state.widgets[currentIndex];
                this.setState({
                    currentWidget: widget,
                    isLoading: false
                })
            }, 500);

        }
    }
    renderCopyPane() {
        if (this.state.selectedOption === '')
            return '';
        else
            return (
                <div>
                    <hr />
                    <div className="row justify-content-center align-self-center">
                        {
                            this.renderSelectedOption()
                        }
                    </div>
                </div>
            )
    }


    renderMainContent() {
        if (this.state.isLoading) {
            return (
                <ModalBody style={{ 'font-family': 'Barlow,sans-serif' }}>
                    <Spinner></Spinner>
                </ModalBody>
            )
        }
        else {
            return (
                <ModalBody style={{ 'font-family': 'Barlow,sans-serif' }}>
                    <div className="row justify-content-center noOverflow pt-2 pb-3" style={{ textAlign: "center" }}>
                        <div className="col-md-auto">
                            {
                                this.state.currentWidget.originalWidth > 500 && this.props.currentWidth <= 500 ?
                                    <iframe scrolling="no" style={{ transition: "background 1s" }} width={this.props.currentWidth - 100} height={(this.props.currentWidth - 100) / this.state.currentWidget.previewRatio} src={this.state.currentWidget.previewUrl.concat('&width=', (this.props.currentWidth - 100).toString())} ></iframe>
                                    :
                                    <iframe scrolling="no" style={{ transition: "background 1s" }} width={this.state.currentWidget.previewWidth} height={this.state.currentWidget.previewHeight} src={this.state.currentWidget.previewUrl} ></iframe>
                            }
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div class="options">
                            <FormGroup check>
                                <Input
                                    name="radio1"
                                    type="radio"
                                    value="html"
                                    checked={this.state.selectedOption === 'html' ? 'checked' : ''}
                                    onClick={event => this.selectOption(event.target.value)}
                                />
                                <Label check>
                                    <strong>Als Html-Code</strong>
                                    <br />
                                    <small class="pt-1">
                                        Ideal zur Einbettung als html-Snippet in Email-Kampagnen
                                    </small>
                                </Label>
                            </FormGroup>
                            <FormGroup check className="pt-2">
                                <Input
                                    name="radio1"
                                    type="radio"
                                    value="link"
                                    checked={this.state.selectedOption === 'link' ? 'checked' : ''}
                                    onClick={event => this.selectOption(event.target.value)}
                                />
                                <Label check>
                                    <strong>Als verlinktes Bild</strong>
                                    <br />
                                    <small class="pt-1">
                                        Ideal zur Einbettung in E-Mails
                                    </small>
                                </Label>
                            </FormGroup>
                            <FormGroup check className="pt-2">
                                <Input
                                    name="radio1"
                                    type="radio"
                                    value="image"
                                    checked={this.state.selectedOption === 'image' ? 'checked' : ''}
                                    onClick={event => this.selectOption(event.target.value)}
                                />
                                <Label check>
                                    <strong>Nur das Bild</strong>
                                    <br />
                                    <small class="pt-1">
                                        Ideal für den Einbau in Office-Dokumente oder Versand via Messenger
                                    </small>
                                </Label>
                            </FormGroup>
                        </div>
                    </div>
                    {
                        this.renderCopyPane()
                    }
                </ModalBody>
            )
        }
    }
    render() {
        return (
            <div>
                <ToastContainer
                    enableMultiContainer
                    containerId={'HEADER'}
                    position="top-center"
                    autoClose={500}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    pauseOnHover={false}
                    theme="light"
                    style={{ 'font-family': 'Barlow,sans-serif' }}
                />
                <Modal fullscreen="xl" size="xl" backdrop="true" fade="true" scrollable="true" centered="true" isOpen={this.state.displayModal} toggle={this.toggleModal}>
                    <div className="row">
                        <div className="col-md-1 col-lg-1 col-sm-0 justify-content-center align-self-center d-none d-sm-block" style={{ "text-align": "end" }}>
                            {
                                this.canShowSwipePrev() ? <span class=" arrow icon-arrow-back" onClick={(event) => this.swipe(true, false)}></span> : ''
                            }
                        </div>
                        <div className="col-md-10 col-lg-10 col-sm-12 pt-4 pb-4" style={{ "background-color": "rgb(245, 245, 245)" }}>
                            <ModalHeader toggle={this.toggleModal}>
                                <div className="row justify-content-center">
                                    <div className="col-md-auto text-center align-self-center">
                                        <img height={48} width={161} className="inverted" src={logo}></img>
                                    </div>
                                </div>
                                <div className="row pt-4 justify-content-center">
                                    <div className="col-md-auto text-center">
                                        {this.state.accountName}
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-md-auto text-center">
                                        {this.state.currentWidget.widgetFormatLabel}
                                    </div>
                                </div>
                            </ModalHeader>
                            {
                                this.renderMainContent()
                            }
                            {
                                this.renderFooter()
                            }

                        </div>

                        <div className="col-md-1 col-lg-1 col-sm-0 justify-content-center align-self-center d-none d-sm-block" style={{ "text-align": "start" }}>
                            {
                                this.canShowSwipeNext() ? <span class="arrow icon-arrow-next" onClick={(event) => this.swipe(false, true)}></span> : ''
                            }
                        </div>



                    </div>



                </Modal >
            </div>

        );
    }
}

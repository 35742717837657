import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import { Provider } from 'react-redux'
import './resources/scss/app.scss';
import { storeProvider } from './store/storeProvider';

export default class App extends Component {
    static displayName = App.name;

    componentDidMount() {
        window.addEventListener('popstate', this.handlePopState);
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.handlePopState);
    }

    handlePopState = () => {
        window.location = window.location.href
    };

    render() {

        return (
            <Provider store={storeProvider.getStore()}>
            <Layout>
                <Routes>
                    {AppRoutes.map((route, index) => {
                        const { element, ...rest } = route;
                        let newElement = Object.assign({}, element)
                        return <Route key={index} {...rest} element={newElement} />;
                    })}
                </Routes>
                </Layout>
            </Provider>
        );
    }
}
